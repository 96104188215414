import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { HelmetProvider, Helmet } from "react-helmet-async";
import { useParams } from 'react-router-dom';
import projectsData from "./ProjectsData.json";


//Lightbox
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import { Col, Container, Row } from "react-bootstrap";

// lazy load
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // you can choose other effects

//a
// images

// Tarapark Villa
import taraparkVillaPhoto10 from "./img/taraparkVillaPhoto (10).jpg";
import taraparkVillaPhoto1 from "./img/taraparkVillaPhoto (1).jpg";
import taraparkVillaPhoto2 from "./img/taraparkVillaPhoto (2).jpg";
import taraparkVillaPhoto3 from "./img/taraparkVillaPhoto (3).jpg";
import taraparkVillaPhoto4 from "./img/taraparkVillaPhoto (4).jpg";
import taraparkVillaPhoto5 from "./img/taraparkVillaPhoto (5).jpg";
import taraparkVillaPhoto6 from "./img/taraparkVillaPhoto (6).jpg";
import taraparkVillaPhoto7 from "./img/taraparkVillaPhoto (7).jpg";
import taraparkVillaPhoto8 from "./img/taraparkVillaPhoto (8).jpg";
import taraparkVillaPhoto9 from "./img/taraparkVillaPhoto (9).jpg";
import taraparkVillaPhoto11 from "./img/taraparkVillaPhoto (11).jpg";
import taraparkVillaPhoto12 from "./img/taraparkVillaPhoto (12).jpg";
import taraparkVillaPhoto13 from "./img/taraparkVillaPhoto (13).jpg";
import taraparkVillaPhoto14 from "./img/taraparkVillaPhoto (14).jpg";
import taraparkVillaPhoto15 from "./img/taraparkVillaPhoto (15).jpg";
import taraparkVillaPhoto16 from "./img/taraparkVillaPhoto (16).jpg";
import taraparkVillaPhoto17 from "./img/taraparkVillaPhoto (17).jpg";
import taraparkVillaPhoto18 from "./img/taraparkVillaPhoto (18).jpg";
import taraparkVillaPhoto19 from "./img/taraparkVillaPhoto (19).jpg";
import taraparkVillaPhoto20 from "./img/taraparkVillaPhoto (20).jpg";
import taraparkVillaPhoto21 from "./img/taraparkVillaPhoto (21).jpg";

// Aydinlar Villa
import aydinlarVillaPhoto26 from "./img/aydinlarVillaPhoto (26).jpg";
import aydinlarVillaPhoto1 from "./img/aydinlarVillaPhoto (1).jpg";
import aydinlarVillaPhoto2 from "./img/aydinlarVillaPhoto (2).jpg";
import aydinlarVillaPhoto3 from "./img/aydinlarVillaPhoto (3).jpg";
import aydinlarVillaPhoto4 from "./img/aydinlarVillaPhoto (4).jpg";
import aydinlarVillaPhoto5 from "./img/aydinlarVillaPhoto (5).jpg";
import aydinlarVillaPhoto6 from "./img/aydinlarVillaPhoto (6).jpg";
import aydinlarVillaPhoto7 from "./img/aydinlarVillaPhoto (7).jpg";
import aydinlarVillaPhoto8 from "./img/aydinlarVillaPhoto (8).jpg";
import aydinlarVillaPhoto9 from "./img/aydinlarVillaPhoto (9).jpg";
import aydinlarVillaPhoto10 from "./img/aydinlarVillaPhoto (10).jpg";
import aydinlarVillaPhoto11 from "./img/aydinlarVillaPhoto (11).jpg";
import aydinlarVillaPhoto12 from "./img/aydinlarVillaPhoto (12).jpg";
import aydinlarVillaPhoto13 from "./img/aydinlarVillaPhoto (13).jpg";
import aydinlarVillaPhoto14 from "./img/aydinlarVillaPhoto (14).jpg";
import aydinlarVillaPhoto15 from "./img/aydinlarVillaPhoto (15).jpg";
import aydinlarVillaPhoto16 from "./img/aydinlarVillaPhoto (16).jpg";
import aydinlarVillaPhoto17 from "./img/aydinlarVillaPhoto (17).jpg";
import aydinlarVillaPhoto18 from "./img/aydinlarVillaPhoto (18).jpg";
import aydinlarVillaPhoto19 from "./img/aydinlarVillaPhoto (19).jpg";
import aydinlarVillaPhoto20 from "./img/aydinlarVillaPhoto (20).jpg";
import aydinlarVillaPhoto21 from "./img/aydinlarVillaPhoto (21).jpg";
import aydinlarVillaPhoto22 from "./img/aydinlarVillaPhoto (22).jpg";
import aydinlarVillaPhoto23 from "./img/aydinlarVillaPhoto (23).jpg";
import aydinlarVillaPhoto24 from "./img/aydinlarVillaPhoto (24).jpg";
import aydinlarVillaPhoto25 from "./img/aydinlarVillaPhoto (25).jpg";


// bahçelievler konutları
import bahcelievlerKonutPhoto3 from "./img/bahcelievlerKonutPhoto (3).jpg";
import bahcelievlerKonutPhoto1 from "./img/bahcelievlerKonutPhoto (1).jpg";
import bahcelievlerKonutPhoto2 from "./img/bahcelievlerKonutPhoto (2).jpg";
import bahcelievlerKonutPhoto4 from "./img/bahcelievlerKonutPhoto (4).jpg";
import bahcelievlerKonutPhoto5 from "./img/bahcelievlerKonutPhoto (5).jpg";
import bahcelievlerKonutPhoto6 from "./img/bahcelievlerKonutPhoto (6).jpg";
import bahcelievlerKonutPhoto7 from "./img/bahcelievlerKonutPhoto (7).jpg";
import bahcelievlerKonutPhoto8 from "./img/bahcelievlerKonutPhoto (8).jpg";
import bahcelievlerKonutPhoto9 from "./img/bahcelievlerKonutPhoto (9).jpg";
import bahcelievlerKonutPhoto10 from "./img/bahcelievlerKonutPhoto (10).jpg";
import bahcelievlerKonutPhoto11 from "./img/bahcelievlerKonutPhoto (11).jpg";
import bahcelievlerKonutPhoto12 from "./img/bahcelievlerKonutPhoto (12).jpg";
import bahcelievlerKonutPhoto13 from "./img/bahcelievlerKonutPhoto (13).jpg";
import bahcelievlerKonutPhoto14 from "./img/bahcelievlerKonutPhoto (14).jpg";
import bahcelievlerKonutPhoto15 from "./img/bahcelievlerKonutPhoto (15).jpg";
import bahcelievlerKonutPhoto16 from "./img/bahcelievlerKonutPhoto (16).jpg";
import bahcelievlerKonutPhoto17 from "./img/bahcelievlerKonutPhoto (17).jpg";
import bahcelievlerKonutPhoto18 from "./img/bahcelievlerKonutPhoto (18).jpg";
import bahcelievlerKonutPhoto19 from "./img/bahcelievlerKonutPhoto (19).jpg";
import bahcelievlerKonutPhoto20 from "./img/bahcelievlerKonutPhoto (20).jpg";
import bahcelievlerKonutPhoto21 from "./img/bahcelievlerKonutPhoto (21).jpg";
import bahcelievlerKonutPhoto22 from "./img/bahcelievlerKonutPhoto (22).jpg";
import bahcelievlerKonutPhoto23 from "./img/bahcelievlerKonutPhoto (23).jpg";
import bahcelievlerKonutPhoto24 from "./img/bahcelievlerKonutPhoto (24).jpg";
import bahcelievlerKonutPhoto25 from "./img/bahcelievlerKonutPhoto (25).jpg";
import bahcelievlerKonutPhoto26 from "./img/bahcelievlerKonutPhoto (26).jpg";
import bahcelievlerKonutPhoto27 from "./img/bahcelievlerKonutPhoto (27).jpg";
import bahcelievlerKonutPhoto28 from "./img/bahcelievlerKonutPhoto (28).jpg";

// dbn inşaat
import dbnInsaatOfficePhoto23 from "./img/dbnInsaatOfficePhoto (23).jpg";
import dbnInsaatOfficePhoto1 from "./img/dbnInsaatOfficePhoto (1).jpg";
import dbnInsaatOfficePhoto2 from "./img/dbnInsaatOfficePhoto (2).jpg";
import dbnInsaatOfficePhoto3 from "./img/dbnInsaatOfficePhoto (3).jpg";
import dbnInsaatOfficePhoto4 from "./img/dbnInsaatOfficePhoto (4).jpg";
import dbnInsaatOfficePhoto5 from "./img/dbnInsaatOfficePhoto (5).jpg";
import dbnInsaatOfficePhoto6 from "./img/dbnInsaatOfficePhoto (6).jpg";
import dbnInsaatOfficePhoto7 from "./img/dbnInsaatOfficePhoto (7).jpg";
import dbnInsaatOfficePhoto8 from "./img/dbnInsaatOfficePhoto (8).jpg";
import dbnInsaatOfficePhoto9 from "./img/dbnInsaatOfficePhoto (9).jpg";
import dbnInsaatOfficePhoto10 from "./img/dbnInsaatOfficePhoto (10).jpg";
import dbnInsaatOfficePhoto11 from "./img/dbnInsaatOfficePhoto (11).jpg";
import dbnInsaatOfficePhoto12 from "./img/dbnInsaatOfficePhoto (12).jpg";
import dbnInsaatOfficePhoto13 from "./img/dbnInsaatOfficePhoto (13).jpg";
import dbnInsaatOfficePhoto14 from "./img/dbnInsaatOfficePhoto (14).jpg";
import dbnInsaatOfficePhoto15 from "./img/dbnInsaatOfficePhoto (15).jpg";
import dbnInsaatOfficePhoto16 from "./img/dbnInsaatOfficePhoto (16).jpg";
import dbnInsaatOfficePhoto17 from "./img/dbnInsaatOfficePhoto (17).jpg";
import dbnInsaatOfficePhoto18 from "./img/dbnInsaatOfficePhoto (18).jpg";
import dbnInsaatOfficePhoto19 from "./img/dbnInsaatOfficePhoto (19).jpg";
import dbnInsaatOfficePhoto20 from "./img/dbnInsaatOfficePhoto (20).jpg";
import dbnInsaatOfficePhoto21 from "./img/dbnInsaatOfficePhoto (21).jpg";
import dbnInsaatOfficePhoto22 from "./img/dbnInsaatOfficePhoto (22).jpg";

// hill boutique
import hillBoutiquePhoto20 from "./img/hillBoutiquePhoto (20).jpg";
import hillBoutiquePhoto1 from "./img/hillBoutiquePhoto (1).jpg";
import hillBoutiquePhoto2 from "./img/hillBoutiquePhoto (2).jpg";
import hillBoutiquePhoto3 from "./img/hillBoutiquePhoto (3).jpg";
import hillBoutiquePhoto4 from "./img/hillBoutiquePhoto (4).jpg";
import hillBoutiquePhoto5 from "./img/hillBoutiquePhoto (5).jpg";
import hillBoutiquePhoto6 from "./img/hillBoutiquePhoto (6).jpg";
import hillBoutiquePhoto7 from "./img/hillBoutiquePhoto (7).jpg";
import hillBoutiquePhoto8 from "./img/hillBoutiquePhoto (8).jpg";
import hillBoutiquePhoto9 from "./img/hillBoutiquePhoto (9).jpg";
import hillBoutiquePhoto10 from "./img/hillBoutiquePhoto (10).jpg";
import hillBoutiquePhoto11 from "./img/hillBoutiquePhoto (11).jpg";
import hillBoutiquePhoto12 from "./img/hillBoutiquePhoto (12).jpg";
import hillBoutiquePhoto13 from "./img/hillBoutiquePhoto (13).jpg";
import hillBoutiquePhoto14 from "./img/hillBoutiquePhoto (14).jpg";
import hillBoutiquePhoto15 from "./img/hillBoutiquePhoto (15).jpg";
import hillBoutiquePhoto16 from "./img/hillBoutiquePhoto (16).jpg";
import hillBoutiquePhoto17 from "./img/hillBoutiquePhoto (17).jpg";
import hillBoutiquePhoto18 from "./img/hillBoutiquePhoto (18).jpg";
import hillBoutiquePhoto19 from "./img/hillBoutiquePhoto (19).jpg";



// ilona konutları
import ilonaKonutlarıPhoto1 from "./img/ilonaKonutlarıPhoto (1).jpg";
import ilonaKonutlarıPhoto2 from "./img/ilonaKonutlarıPhoto (2).jpg";
import ilonaKonutlarıPhoto3 from "./img/ilonaKonutlarıPhoto (3).jpg";
import ilonaKonutlarıPhoto4 from "./img/ilonaKonutlarıPhoto (4).jpg";
import ilonaKonutlarıPhoto5 from "./img/ilonaKonutlarıPhoto (5).jpg";
import ilonaKonutlarıPhoto6 from "./img/ilonaKonutlarıPhoto (6).jpg";
import ilonaKonutlarıPhoto7 from "./img/ilonaKonutlarıPhoto (7).jpg";
import ilonaKonutlarıPhoto8 from "./img/ilonaKonutlarıPhoto (8).jpg";
import ilonaKonutlarıPhoto9 from "./img/ilonaKonutlarıPhoto (9).jpg";
import ilonaKonutlarıPhoto10 from "./img/ilonaKonutlarıPhoto (10).jpg";
import ilonaKonutlarıPhoto11 from "./img/ilonaKonutlarıPhoto (11).jpg";
import ilonaKonutlarıPhoto12 from "./img/ilonaKonutlarıPhoto (12).jpg";
import ilonaKonutlarıPhoto13 from "./img/ilonaKonutlarıPhoto (13).jpg";
import ilonaKonutlarıPhoto14 from "./img/ilonaKonutlarıPhoto (14).jpg";
import ilonaKonutlarıPhoto15 from "./img/ilonaKonutlarıPhoto (15).jpg";
import ilonaKonutlarıPhoto16 from "./img/ilonaKonutlarıPhoto (16).jpg";
import ilonaKonutlarıPhoto17 from "./img/ilonaKonutlarıPhoto (17).jpg";
import ilonaKonutlarıPhoto18 from "./img/ilonaKonutlarıPhoto (18).jpg";
import ilonaKonutlarıPhoto19 from "./img/ilonaKonutlarıPhoto (19).jpg";
import ilonaKonutlarıPhoto20 from "./img/ilonaKonutlarıPhoto (20).jpg";
import ilonaKonutlarıPhoto21 from "./img/ilonaKonutlarıPhoto (21).jpg";
import ilonaKonutlarıPhoto22 from "./img/ilonaKonutlarıPhoto (22).jpg";
import ilonaKonutlarıPhoto23 from "./img/ilonaKonutlarıPhoto (23).jpg";
import ilonaKonutlarıPhoto24 from "./img/ilonaKonutlarıPhoto (24).jpg";
import ilonaKonutlarıPhoto25 from "./img/ilonaKonutlarıPhoto (25).jpg";
import ilonaKonutlarıPhoto26 from "./img/ilonaKonutlarıPhoto (26).jpg";
import ilonaKonutlarıPhoto27 from "./img/ilonaKonutlarıPhoto (27).jpg";
import ilonaKonutlarıPhoto28 from "./img/ilonaKonutlarıPhoto (28).jpg";
import ilonaKonutlarıPhoto29 from "./img/ilonaKonutlarıPhoto (29).jpg";
import ilonaKonutlarıPhoto30 from "./img/ilonaKonutlarıPhoto (30).jpg";
import ilonaKonutlarıPhoto31 from "./img/ilonaKonutlarıPhoto (31).jpg";
import ilonaKonutlarıPhoto32 from "./img/ilonaKonutlarıPhoto (32).jpg";
import ilonaKonutlarıPhoto33 from "./img/ilonaKonutlarıPhoto (33).jpg";
import ilonaKonutlarıPhoto34 from "./img/ilonaKonutlarıPhoto (34).jpg";
import ilonaKonutlarıPhoto35 from "./img/ilonaKonutlarıPhoto (35).jpg";
import ilonaKonutlarıPhoto36 from "./img/ilonaKonutlarıPhoto (36).jpg";
import ilonaKonutlarıPhoto37 from "./img/ilonaKonutlarıPhoto (37).jpg";
import ilonaKonutlarıPhoto38 from "./img/ilonaKonutlarıPhoto (38).jpg";
import ilonaKonutlarıPhoto39 from "./img/ilonaKonutlarıPhoto (39).jpg";
import ilonaKonutlarıPhoto40 from "./img/ilonaKonutlarıPhoto (40).jpg";
import ilonaKonutlarıPhoto41 from "./img/ilonaKonutlarıPhoto (41).jpg";
import ilonaKonutlarıPhoto42 from "./img/ilonaKonutlarıPhoto (42).jpg";
import ilonaKonutlarıPhoto43 from "./img/ilonaKonutlarıPhoto (43).jpg";
import ilonaKonutlarıPhoto44 from "./img/ilonaKonutlarıPhoto (44).jpg";
import ilonaKonutlarıPhoto45 from "./img/ilonaKonutlarıPhoto (45).jpg";


// meva villaları
import mevaVillalariPhoto30 from "./img/mevaVillalariPhoto (30).jpg";
import mevaVillalariPhoto1 from "./img/mevaVillalariPhoto (1).jpg";
import mevaVillalariPhoto2 from "./img/mevaVillalariPhoto (2).jpg";
import mevaVillalariPhoto3 from "./img/mevaVillalariPhoto (3).jpg";
import mevaVillalariPhoto4 from "./img/mevaVillalariPhoto (4).jpg";

import mevaVillalariPhoto6 from "./img/mevaVillalariPhoto (6).jpg";
import mevaVillalariPhoto7 from "./img/mevaVillalariPhoto (7).jpg";
import mevaVillalariPhoto8 from "./img/mevaVillalariPhoto (8).jpg";
import mevaVillalariPhoto9 from "./img/mevaVillalariPhoto (9).jpg";
import mevaVillalariPhoto10 from "./img/mevaVillalariPhoto (10).jpg";
import mevaVillalariPhoto11 from "./img/mevaVillalariPhoto (11).jpg";
import mevaVillalariPhoto12 from "./img/mevaVillalariPhoto (12).jpg";
import mevaVillalariPhoto13 from "./img/mevaVillalariPhoto (13).jpg";
import mevaVillalariPhoto14 from "./img/mevaVillalariPhoto (14).jpg";
import mevaVillalariPhoto15 from "./img/mevaVillalariPhoto (15).jpg";
import mevaVillalariPhoto16 from "./img/mevaVillalariPhoto (16).jpg";
import mevaVillalariPhoto17 from "./img/mevaVillalariPhoto (17).jpg";
import mevaVillalariPhoto18 from "./img/mevaVillalariPhoto (18).jpg";
import mevaVillalariPhoto19 from "./img/mevaVillalariPhoto (19).jpg";
import mevaVillalariPhoto20 from "./img/mevaVillalariPhoto (20).jpg";
import mevaVillalariPhoto21 from "./img/mevaVillalariPhoto (21).jpg";
import mevaVillalariPhoto22 from "./img/mevaVillalariPhoto (22).jpg";

import mevaVillalariPhoto24 from "./img/mevaVillalariPhoto (24).jpg";
import mevaVillalariPhoto25 from "./img/mevaVillalariPhoto (25).jpg";
import mevaVillalariPhoto26 from "./img/mevaVillalariPhoto (26).jpg";
import mevaVillalariPhoto27 from "./img/mevaVillalariPhoto (27).jpg";
import mevaVillalariPhoto28 from "./img/mevaVillalariPhoto (28).jpg";
import mevaVillalariPhoto29 from "./img/mevaVillalariPhoto (29).jpg";



// otokent oto ekspertiz
import otokentOtoEkspertizPhoto18 from "./img/otokentOtoEkspertizPhoto (18).jpg";
import otokentOtoEkspertizPhoto1 from "./img/otokentOtoEkspertizPhoto (1).jpg";
import otokentOtoEkspertizPhoto2 from "./img/otokentOtoEkspertizPhoto (2).jpg";
import otokentOtoEkspertizPhoto3 from "./img/otokentOtoEkspertizPhoto (3).jpg";
import otokentOtoEkspertizPhoto4 from "./img/otokentOtoEkspertizPhoto (4).jpg";
import otokentOtoEkspertizPhoto5 from "./img/otokentOtoEkspertizPhoto (5).jpg";
import otokentOtoEkspertizPhoto6 from "./img/otokentOtoEkspertizPhoto (6).jpg";
import otokentOtoEkspertizPhoto7 from "./img/otokentOtoEkspertizPhoto (7).jpg";
import otokentOtoEkspertizPhoto8 from "./img/otokentOtoEkspertizPhoto (8).jpg";
import otokentOtoEkspertizPhoto9 from "./img/otokentOtoEkspertizPhoto (9).jpg";
import otokentOtoEkspertizPhoto10 from "./img/otokentOtoEkspertizPhoto (10).jpg";
import otokentOtoEkspertizPhoto11 from "./img/otokentOtoEkspertizPhoto (11).jpg";
import otokentOtoEkspertizPhoto12 from "./img/otokentOtoEkspertizPhoto (12).jpg";
import otokentOtoEkspertizPhoto13 from "./img/otokentOtoEkspertizPhoto (13).jpg";
import otokentOtoEkspertizPhoto14 from "./img/otokentOtoEkspertizPhoto (14).jpg";
import otokentOtoEkspertizPhoto15 from "./img/otokentOtoEkspertizPhoto (15).jpg";
import otokentOtoEkspertizPhoto16 from "./img/otokentOtoEkspertizPhoto (16).jpg";
import otokentOtoEkspertizPhoto17 from "./img/otokentOtoEkspertizPhoto (17).jpg";

// van kahvaltı
import vanKahvaltiPhoto15 from "./img/vanKahvaltiPhoto (15).jpg";
import vanKahvaltiPhoto1 from "./img/vanKahvaltiPhoto (1).jpg";
import vanKahvaltiPhoto2 from "./img/vanKahvaltiPhoto (2).jpg";
import vanKahvaltiPhoto3 from "./img/vanKahvaltiPhoto (3).jpg";
import vanKahvaltiPhoto4 from "./img/vanKahvaltiPhoto (4).jpg";
import vanKahvaltiPhoto5 from "./img/vanKahvaltiPhoto (5).jpg";
import vanKahvaltiPhoto6 from "./img/vanKahvaltiPhoto (6).jpg";
import vanKahvaltiPhoto7 from "./img/vanKahvaltiPhoto (7).jpg";
import vanKahvaltiPhoto8 from "./img/vanKahvaltiPhoto (8).jpg";
import vanKahvaltiPhoto9 from "./img/vanKahvaltiPhoto (9).jpg";
import vanKahvaltiPhoto10 from "./img/vanKahvaltiPhoto (10).jpg";
import vanKahvaltiPhoto11 from "./img/vanKahvaltiPhoto (11).jpg";
import vanKahvaltiPhoto12 from "./img/vanKahvaltiPhoto (12).jpg";
import vanKahvaltiPhoto13 from "./img/vanKahvaltiPhoto (13).jpg";
import vanKahvaltiPhoto14 from "./img/vanKahvaltiPhoto (14).jpg";

// coffee kiosk
import coffeeKioskPhoto5 from "./img/coffeeKioskPhoto (5).jpg";
import coffeeKioskPhoto1 from "./img/coffeeKioskPhoto (1).jpg";
import coffeeKioskPhoto2 from "./img/coffeeKioskPhoto (2).jpg";
import coffeeKioskPhoto3 from "./img/coffeeKioskPhoto (3).jpg";
import coffeeKioskPhoto4 from "./img/coffeeKioskPhoto (4).jpg";

// germany hukuk ofis
import germanyHukukOfficePhoto6 from "./img/germanyHukukOfficePhoto (6).jpg";
import germanyHukukOfficePhoto1 from "./img/germanyHukukOfficePhoto (1).jpg";
import germanyHukukOfficePhoto2 from "./img/germanyHukukOfficePhoto (2).jpg";
import germanyHukukOfficePhoto3 from "./img/germanyHukukOfficePhoto (3).jpg";
import germanyHukukOfficePhoto4 from "./img/germanyHukukOfficePhoto (4).jpg";
import germanyHukukOfficePhoto5 from "./img/germanyHukukOfficePhoto (5).jpg";

// germany loft daire
import germanyLoftDairePhoto3 from "./img/germanyLoftDairePhoto (3).jpg";
import germanyLoftDairePhoto1 from "./img/germanyLoftDairePhoto (1).jpg";
import germanyLoftDairePhoto2 from "./img/germanyLoftDairePhoto (2).jpg";

// manolya mobilya mağaza
import manolyaMobilyaMağazaPhoto7 from "./img/manolyaMobilyaMağazaPhoto (7).jpg";
import manolyaMobilyaMağazaPhoto1 from "./img/manolyaMobilyaMağazaPhoto (1).jpg";
import manolyaMobilyaMağazaPhoto2 from "./img/manolyaMobilyaMağazaPhoto (2).jpg";
import manolyaMobilyaMağazaPhoto3 from "./img/manolyaMobilyaMağazaPhoto (3).jpg";
import manolyaMobilyaMağazaPhoto4 from "./img/manolyaMobilyaMağazaPhoto (4).jpg";
import manolyaMobilyaMağazaPhoto5 from "./img/manolyaMobilyaMağazaPhoto (5).jpg";
import manolyaMobilyaMağazaPhoto6 from "./img/manolyaMobilyaMağazaPhoto (6).jpg";


// urla müstakil konut
import urlaMustakilKonutPhoto4 from "./img/urlaMustakilKonutPhoto (4).jpg";
import urlaMustakilKonutPhoto1 from "./img/urlaMustakilKonutPhoto (1).jpg";
import urlaMustakilKonutPhoto2 from "./img/urlaMustakilKonutPhoto (2).jpg";
import urlaMustakilKonutPhoto3 from "./img/urlaMustakilKonutPhoto (3).jpg";

import yasamkentVillaPhoto21 from "./img/yasamkentVillaPhoto (21).jpg";
import yasamkentVillaPhoto1 from "./img/yasamkentVillaPhoto (1).jpg";
import yasamkentVillaPhoto2 from "./img/yasamkentVillaPhoto (2).jpg";
import yasamkentVillaPhoto3 from "./img/yasamkentVillaPhoto (3).jpg";
import yasamkentVillaPhoto4 from "./img/yasamkentVillaPhoto (4).jpg";
import yasamkentVillaPhoto5 from "./img/yasamkentVillaPhoto (5).jpg";
import yasamkentVillaPhoto6 from "./img/yasamkentVillaPhoto (6).jpg";
import yasamkentVillaPhoto7 from "./img/yasamkentVillaPhoto (7).jpg";
import yasamkentVillaPhoto8 from "./img/yasamkentVillaPhoto (8).jpg";
import yasamkentVillaPhoto9 from "./img/yasamkentVillaPhoto (9).jpg";
import yasamkentVillaPhoto10 from "./img/yasamkentVillaPhoto (10).jpg";
import yasamkentVillaPhoto11 from "./img/yasamkentVillaPhoto (11).jpg";
import yasamkentVillaPhoto12 from "./img/yasamkentVillaPhoto (12).jpg";
import yasamkentVillaPhoto13 from "./img/yasamkentVillaPhoto (13).jpg";
import yasamkentVillaPhoto14 from "./img/yasamkentVillaPhoto (14).jpg";
import yasamkentVillaPhoto15 from "./img/yasamkentVillaPhoto (15).jpg";
import yasamkentVillaPhoto16 from "./img/yasamkentVillaPhoto (16).jpg";
import yasamkentVillaPhoto17 from "./img/yasamkentVillaPhoto (17).jpg";
import yasamkentVillaPhoto18 from "./img/yasamkentVillaPhoto (18).jpg";
import yasamkentVillaPhoto19 from "./img/yasamkentVillaPhoto (19).jpg";
import yasamkentVillaPhoto20 from "./img/yasamkentVillaPhoto (20).jpg";

import vigInternationalPhoto4 from "./img/vigInternationalPhoto (4).jpg";
import vigInternationalPhoto1 from "./img/vigInternationalPhoto (1).jpg";
import vigInternationalPhoto2 from "./img/vigInternationalPhoto (2).jpg";
import vigInternationalPhoto3 from "./img/vigInternationalPhoto (3).jpg";

import piyapInsaatPhoto12 from "./img/piyapInsaatPhoto (12).jpg";
import piyapInsaatPhoto1 from "./img/piyapInsaatPhoto (1).jpg";
import piyapInsaatPhoto2 from "./img/piyapInsaatPhoto (2).jpg";
import piyapInsaatPhoto3 from "./img/piyapInsaatPhoto (3).jpg";
import piyapInsaatPhoto4 from "./img/piyapInsaatPhoto (4).jpg";
import piyapInsaatPhoto5 from "./img/piyapInsaatPhoto (5).jpg";
import piyapInsaatPhoto6 from "./img/piyapInsaatPhoto (6).jpg";
import piyapInsaatPhoto7 from "./img/piyapInsaatPhoto (7).jpg";
import piyapInsaatPhoto8 from "./img/piyapInsaatPhoto (8).jpg";
import piyapInsaatPhoto9 from "./img/piyapInsaatPhoto (9).jpg";
import piyapInsaatPhoto10 from "./img/piyapInsaatPhoto (10).jpg";
import piyapInsaatPhoto11 from "./img/piyapInsaatPhoto (11).jpg";

import pasifikEurasiaPhoto4 from "./img/pasifikEurasiaPhoto (4).jpg";
import pasifikEurasiaPhoto1 from "./img/pasifikEurasiaPhoto (1).jpg";
import pasifikEurasiaPhoto2 from "./img/pasifikEurasiaPhoto (2).jpg";
import pasifikEurasiaPhoto3 from "./img/pasifikEurasiaPhoto (3).jpg";

import normTechnologyPhoto3 from "./img/normTechnologyPhoto (3).jpg";
import normTechnologyPhoto1 from "./img/normTechnologyPhoto (1).jpg";
import normTechnologyPhoto2 from "./img/normTechnologyPhoto (2).jpg";

import gazselElektromekanikPhoto18 from "./img/gazselElektromekanikPhoto (18).jpg";
import gazselElektromekanikPhoto1 from "./img/gazselElektromekanikPhoto (1).jpg";
import gazselElektromekanikPhoto2 from "./img/gazselElektromekanikPhoto (2).jpg";
import gazselElektromekanikPhoto3 from "./img/gazselElektromekanikPhoto (3).jpg";
import gazselElektromekanikPhoto4 from "./img/gazselElektromekanikPhoto (4).jpg";
import gazselElektromekanikPhoto5 from "./img/gazselElektromekanikPhoto (5).jpg";
import gazselElektromekanikPhoto6 from "./img/gazselElektromekanikPhoto (6).jpg";
import gazselElektromekanikPhoto7 from "./img/gazselElektromekanikPhoto (7).jpg";
import gazselElektromekanikPhoto8 from "./img/gazselElektromekanikPhoto (8).jpg";
import gazselElektromekanikPhoto9 from "./img/gazselElektromekanikPhoto (9).jpg";
import gazselElektromekanikPhoto10 from "./img/gazselElektromekanikPhoto (10).jpg";
import gazselElektromekanikPhoto11 from "./img/gazselElektromekanikPhoto (11).jpg";
import gazselElektromekanikPhoto12 from "./img/gazselElektromekanikPhoto (12).jpg";
import gazselElektromekanikPhoto13 from "./img/gazselElektromekanikPhoto (13).jpg";
import gazselElektromekanikPhoto14 from "./img/gazselElektromekanikPhoto (14).jpg";
import gazselElektromekanikPhoto15 from "./img/gazselElektromekanikPhoto (15).jpg";
import gazselElektromekanikPhoto16 from "./img/gazselElektromekanikPhoto (16).jpg";
import gazselElektromekanikPhoto17 from "./img/gazselElektromekanikPhoto (17).jpg";

import arkasHoldingPhoto5 from "./img/arkasHoldingPhoto (5).jpg";
import arkasHoldingPhoto1 from "./img/arkasHoldingPhoto (1).jpg";
import arkasHoldingPhoto2 from "./img/arkasHoldingPhoto (2).jpg";
import arkasHoldingPhoto3 from "./img/arkasHoldingPhoto (3).jpg";
import arkasHoldingPhoto4 from "./img/arkasHoldingPhoto (4).jpg";

import incekVillaPhoto13 from "./img/incekVillaPhoto (13).jpg";
import incekVillaPhoto1 from "./img/incekVillaPhoto (1).jpg";
import incekVillaPhoto2 from "./img/incekVillaPhoto (2).jpg";
import incekVillaPhoto3 from "./img/incekVillaPhoto (3).jpg";
import incekVillaPhoto4 from "./img/incekVillaPhoto (4).jpg";
import incekVillaPhoto5 from "./img/incekVillaPhoto (5).jpg";
import incekVillaPhoto6 from "./img/incekVillaPhoto (6).jpg";
import incekVillaPhoto7 from "./img/incekVillaPhoto (7).jpg";
import incekVillaPhoto8 from "./img/incekVillaPhoto (8).jpg";
import incekVillaPhoto9 from "./img/incekVillaPhoto (9).jpg";
import incekVillaPhoto10 from "./img/incekVillaPhoto (10).jpg";
import incekVillaPhoto11 from "./img/incekVillaPhoto (11).jpg";
import incekVillaPhoto12 from "./img/incekVillaPhoto (12).jpg";

import isvicreKisBahcesiPhoto7 from "./img/isvicreKisBahcesiPhoto (7).jpg";
import isvicreKisBahcesiPhoto1 from "./img/isvicreKisBahcesiPhoto (1).jpg";
import isvicreKisBahcesiPhoto2 from "./img/isvicreKisBahcesiPhoto (2).jpg";
import isvicreKisBahcesiPhoto3 from "./img/isvicreKisBahcesiPhoto (3).jpg";
import isvicreKisBahcesiPhoto4 from "./img/isvicreKisBahcesiPhoto (4).jpg";
import isvicreKisBahcesiPhoto5 from "./img/isvicreKisBahcesiPhoto (5).jpg";
import isvicreKisBahcesiPhoto6 from "./img/isvicreKisBahcesiPhoto (6).jpg";

// Title images
import taraparkVillaTitlePhoto from "./img/taraparkVillaTitlePhoto.jpg";
import aydinlarVillaTitlePhoto from "./img/aydinlarVillaTitlePhoto.jpg";
import bahcelievlerKonutTitlePhoto from "./img/bahcelievlerKonutTitlePhoto.jpg";
import dbnInsaatOfficeTitlePhoto from "./img/dbnInsaatOfficeTitlePhoto.jpg";
import hillBoutiqueTitlePhoto from "./img/hillBoutiqueTitlePhoto.jpg";
import ilonaKonutlarıTitlePhoto from "./img/ilonaKonutlarıTitlePhoto.jpg";
import mevaVillalariTitlePhoto from "./img/mevaVillalariTitlePhoto.jpg";
import otokentOtoEkspertizTitlePhoto from "./img/otokentOtoEkspertizTitlePhoto.jpg";
import vanKahvaltiTitlePhoto from "./img/vanKahvaltiTitlePhoto.jpg";
import germanyHukukOfficeTitlePhoto from "./img/germanyHukukOfficeTitlePhoto.jpg";
import germanyLoftDaireTitlePhoto from "./img/germanyLoftDaireTitlePhoto.jpg";
import coffeeKioskTitlePhoto from "./img/coffeeKioskTitlePhoto.jpg";
import manolyaMobilyaMağazaTitlePhoto from "./img/manolyaMobilyaMağazaTitlePhoto.jpg";
import urlaMustakilKonutTitlePhoto from "./img/urlaMustakilKonutTitlePhoto.jpg";
import yasamkentVillaTitlePhoto from "./img/yasamkentVillaTitlePhoto.jpg";
import vigInternationalTitlePhoto from "./img/vigInternationalTitlePhoto.jpg";
import piyapInsaatTitlePhoto from "./img/piyapInsaatTitlePhoto.jpg";
import pasifikEurasiaTitlePhoto from "./img/pasifikEurasiaTitlePhoto.jpg";
import normTechnologyTitlePhoto from "./img/normTechnologyTitlePhoto.jpg";
import gazselElektromekanikTitlePhoto from "./img/gazselElektromekanikTitlePhoto.jpg";
import arkasHoldingTitlePhoto from "./img/arkasHoldingTitlePhoto.jpg";
import incekVillaTitlePhoto from "./img/incekVillaTitlePhoto.jpg";
import isvicreKisBahcesiTitlePhoto from "./img/isvicreKisBahcesiTitlePhoto.jpg";


function ProjectDetail() {
    const { t } = useTranslation();

    const importedImages = {
        "taraparkVillaPhoto10": taraparkVillaPhoto10,
        "taraparkVillaPhoto1": taraparkVillaPhoto1,
        "taraparkVillaPhoto2": taraparkVillaPhoto2,
        "taraparkVillaPhoto3": taraparkVillaPhoto3,
        "taraparkVillaPhoto4": taraparkVillaPhoto4,
        "taraparkVillaPhoto5": taraparkVillaPhoto5,
        "taraparkVillaPhoto6": taraparkVillaPhoto6,
        "taraparkVillaPhoto7": taraparkVillaPhoto7,
        "taraparkVillaPhoto8": taraparkVillaPhoto8,
        "taraparkVillaPhoto9": taraparkVillaPhoto9,
        "taraparkVillaPhoto11": taraparkVillaPhoto11,
        "taraparkVillaPhoto12": taraparkVillaPhoto12,
        "taraparkVillaPhoto13": taraparkVillaPhoto13,
        "taraparkVillaPhoto14": taraparkVillaPhoto14,
        "taraparkVillaPhoto15": taraparkVillaPhoto15,
        "taraparkVillaPhoto16": taraparkVillaPhoto16,
        "taraparkVillaPhoto17": taraparkVillaPhoto17,
        "taraparkVillaPhoto18": taraparkVillaPhoto18,
        "taraparkVillaPhoto19": taraparkVillaPhoto19,
        "taraparkVillaPhoto20": taraparkVillaPhoto20,
        "taraparkVillaPhoto21": taraparkVillaPhoto21,

        "aydinlarVillaPhoto26": aydinlarVillaPhoto26,
        "aydinlarVillaPhoto1": aydinlarVillaPhoto1,
        "aydinlarVillaPhoto2": aydinlarVillaPhoto2,
        "aydinlarVillaPhoto3": aydinlarVillaPhoto3,
        "aydinlarVillaPhoto4": aydinlarVillaPhoto4,
        "aydinlarVillaPhoto5": aydinlarVillaPhoto5,
        "aydinlarVillaPhoto6": aydinlarVillaPhoto6,
        "aydinlarVillaPhoto7": aydinlarVillaPhoto7,
        "aydinlarVillaPhoto8": aydinlarVillaPhoto8,
        "aydinlarVillaPhoto9": aydinlarVillaPhoto9,
        "aydinlarVillaPhoto10": aydinlarVillaPhoto10,
        "aydinlarVillaPhoto11": aydinlarVillaPhoto11,
        "aydinlarVillaPhoto12": aydinlarVillaPhoto12,
        "aydinlarVillaPhoto13": aydinlarVillaPhoto13,
        "aydinlarVillaPhoto14": aydinlarVillaPhoto14,
        "aydinlarVillaPhoto15": aydinlarVillaPhoto15,
        "aydinlarVillaPhoto16": aydinlarVillaPhoto16,
        "aydinlarVillaPhoto17": aydinlarVillaPhoto17,
        "aydinlarVillaPhoto18": aydinlarVillaPhoto18,
        "aydinlarVillaPhoto19": aydinlarVillaPhoto19,
        "aydinlarVillaPhoto20": aydinlarVillaPhoto20,
        "aydinlarVillaPhoto21": aydinlarVillaPhoto21,
        "aydinlarVillaPhoto22": aydinlarVillaPhoto22,
        "aydinlarVillaPhoto23": aydinlarVillaPhoto23,
        "aydinlarVillaPhoto24": aydinlarVillaPhoto24,
        "aydinlarVillaPhoto25": aydinlarVillaPhoto25,

        "ilonaKonutlarıPhoto1": ilonaKonutlarıPhoto1,
        "ilonaKonutlarıPhoto2": ilonaKonutlarıPhoto2,
        "ilonaKonutlarıPhoto3": ilonaKonutlarıPhoto3,
        "ilonaKonutlarıPhoto4": ilonaKonutlarıPhoto4,
        "ilonaKonutlarıPhoto5": ilonaKonutlarıPhoto5,
        "ilonaKonutlarıPhoto6": ilonaKonutlarıPhoto6,
        "ilonaKonutlarıPhoto7": ilonaKonutlarıPhoto7,
        "ilonaKonutlarıPhoto8": ilonaKonutlarıPhoto8,
        "ilonaKonutlarıPhoto9": ilonaKonutlarıPhoto9,
        "ilonaKonutlarıPhoto10": ilonaKonutlarıPhoto10,
        "ilonaKonutlarıPhoto11": ilonaKonutlarıPhoto11,
        "ilonaKonutlarıPhoto12": ilonaKonutlarıPhoto12,
        "ilonaKonutlarıPhoto13": ilonaKonutlarıPhoto13,
        "ilonaKonutlarıPhoto14": ilonaKonutlarıPhoto14,
        "ilonaKonutlarıPhoto15": ilonaKonutlarıPhoto15,
        "ilonaKonutlarıPhoto16": ilonaKonutlarıPhoto16,
        "ilonaKonutlarıPhoto17": ilonaKonutlarıPhoto17,
        "ilonaKonutlarıPhoto18": ilonaKonutlarıPhoto18,
        "ilonaKonutlarıPhoto19": ilonaKonutlarıPhoto19,
        "ilonaKonutlarıPhoto20": ilonaKonutlarıPhoto20,
        "ilonaKonutlarıPhoto21": ilonaKonutlarıPhoto21,
        "ilonaKonutlarıPhoto22": ilonaKonutlarıPhoto22,
        "ilonaKonutlarıPhoto23": ilonaKonutlarıPhoto23,
        "ilonaKonutlarıPhoto24": ilonaKonutlarıPhoto24,
        "ilonaKonutlarıPhoto25": ilonaKonutlarıPhoto25,
        "ilonaKonutlarıPhoto26": ilonaKonutlarıPhoto26,
        "ilonaKonutlarıPhoto27": ilonaKonutlarıPhoto27,
        "ilonaKonutlarıPhoto28": ilonaKonutlarıPhoto28,
        "ilonaKonutlarıPhoto29": ilonaKonutlarıPhoto29,
        "ilonaKonutlarıPhoto30": ilonaKonutlarıPhoto30,
        "ilonaKonutlarıPhoto31": ilonaKonutlarıPhoto31,
        "ilonaKonutlarıPhoto32": ilonaKonutlarıPhoto32,
        "ilonaKonutlarıPhoto33": ilonaKonutlarıPhoto33,
        "ilonaKonutlarıPhoto34": ilonaKonutlarıPhoto34,
        "ilonaKonutlarıPhoto35": ilonaKonutlarıPhoto35,
        "ilonaKonutlarıPhoto36": ilonaKonutlarıPhoto36,
        "ilonaKonutlarıPhoto37": ilonaKonutlarıPhoto37,
        "ilonaKonutlarıPhoto38": ilonaKonutlarıPhoto38,
        "ilonaKonutlarıPhoto39": ilonaKonutlarıPhoto39,
        "ilonaKonutlarıPhoto40": ilonaKonutlarıPhoto40,
        "ilonaKonutlarıPhoto41": ilonaKonutlarıPhoto41,
        "ilonaKonutlarıPhoto42": ilonaKonutlarıPhoto42,
        "ilonaKonutlarıPhoto43": ilonaKonutlarıPhoto43,
        "ilonaKonutlarıPhoto44": ilonaKonutlarıPhoto44,
        "ilonaKonutlarıPhoto45": ilonaKonutlarıPhoto45,

        "bahcelievlerKonutPhoto3": bahcelievlerKonutPhoto3,
        "bahcelievlerKonutPhoto1": bahcelievlerKonutPhoto1,
        "bahcelievlerKonutPhoto2": bahcelievlerKonutPhoto2,
        "bahcelievlerKonutPhoto4": bahcelievlerKonutPhoto4,
        "bahcelievlerKonutPhoto5": bahcelievlerKonutPhoto5,
        "bahcelievlerKonutPhoto6": bahcelievlerKonutPhoto6,
        "bahcelievlerKonutPhoto7": bahcelievlerKonutPhoto7,
        "bahcelievlerKonutPhoto8": bahcelievlerKonutPhoto8,
        "bahcelievlerKonutPhoto9": bahcelievlerKonutPhoto9,
        "bahcelievlerKonutPhoto10": bahcelievlerKonutPhoto10,
        "bahcelievlerKonutPhoto11": bahcelievlerKonutPhoto11,
        "bahcelievlerKonutPhoto12": bahcelievlerKonutPhoto12,
        "bahcelievlerKonutPhoto13": bahcelievlerKonutPhoto13,
        "bahcelievlerKonutPhoto14": bahcelievlerKonutPhoto14,
        "bahcelievlerKonutPhoto15": bahcelievlerKonutPhoto15,
        "bahcelievlerKonutPhoto16": bahcelievlerKonutPhoto16,
        "bahcelievlerKonutPhoto17": bahcelievlerKonutPhoto17,
        "bahcelievlerKonutPhoto18": bahcelievlerKonutPhoto18,
        "bahcelievlerKonutPhoto19": bahcelievlerKonutPhoto19,
        "bahcelievlerKonutPhoto20": bahcelievlerKonutPhoto20,
        "bahcelievlerKonutPhoto21": bahcelievlerKonutPhoto21,
        "bahcelievlerKonutPhoto22": bahcelievlerKonutPhoto22,
        "bahcelievlerKonutPhoto23": bahcelievlerKonutPhoto23,
        "bahcelievlerKonutPhoto24": bahcelievlerKonutPhoto24,
        "bahcelievlerKonutPhoto25": bahcelievlerKonutPhoto25,
        "bahcelievlerKonutPhoto26": bahcelievlerKonutPhoto26,
        "bahcelievlerKonutPhoto27": bahcelievlerKonutPhoto27,
        "bahcelievlerKonutPhoto28": bahcelievlerKonutPhoto28,

        "dbnInsaatOfficePhoto23": dbnInsaatOfficePhoto23,
        "dbnInsaatOfficePhoto1": dbnInsaatOfficePhoto1,
        "dbnInsaatOfficePhoto2": dbnInsaatOfficePhoto2,
        "dbnInsaatOfficePhoto3": dbnInsaatOfficePhoto3,
        "dbnInsaatOfficePhoto4": dbnInsaatOfficePhoto4,
        "dbnInsaatOfficePhoto5": dbnInsaatOfficePhoto5,
        "dbnInsaatOfficePhoto6": dbnInsaatOfficePhoto6,
        "dbnInsaatOfficePhoto7": dbnInsaatOfficePhoto7,
        "dbnInsaatOfficePhoto8": dbnInsaatOfficePhoto8,
        "dbnInsaatOfficePhoto9": dbnInsaatOfficePhoto9,
        "dbnInsaatOfficePhoto10": dbnInsaatOfficePhoto10,
        "dbnInsaatOfficePhoto11": dbnInsaatOfficePhoto11,
        "dbnInsaatOfficePhoto12": dbnInsaatOfficePhoto12,
        "dbnInsaatOfficePhoto13": dbnInsaatOfficePhoto13,
        "dbnInsaatOfficePhoto14": dbnInsaatOfficePhoto14,
        "dbnInsaatOfficePhoto15": dbnInsaatOfficePhoto15,
        "dbnInsaatOfficePhoto16": dbnInsaatOfficePhoto16,
        "dbnInsaatOfficePhoto17": dbnInsaatOfficePhoto17,
        "dbnInsaatOfficePhoto18": dbnInsaatOfficePhoto18,
        "dbnInsaatOfficePhoto19": dbnInsaatOfficePhoto19,
        "dbnInsaatOfficePhoto20": dbnInsaatOfficePhoto20,
        "dbnInsaatOfficePhoto21": dbnInsaatOfficePhoto21,
        "dbnInsaatOfficePhoto22": dbnInsaatOfficePhoto22,

        "hillBoutiquePhoto20": hillBoutiquePhoto20,
        "hillBoutiquePhoto1": hillBoutiquePhoto1,
        "hillBoutiquePhoto2": hillBoutiquePhoto2,
        "hillBoutiquePhoto3": hillBoutiquePhoto3,
        "hillBoutiquePhoto4": hillBoutiquePhoto4,
        "hillBoutiquePhoto5": hillBoutiquePhoto5,
        "hillBoutiquePhoto6": hillBoutiquePhoto6,
        "hillBoutiquePhoto7": hillBoutiquePhoto7,
        "hillBoutiquePhoto8": hillBoutiquePhoto8,
        "hillBoutiquePhoto9": hillBoutiquePhoto9,
        "hillBoutiquePhoto10": hillBoutiquePhoto10,
        "hillBoutiquePhoto11": hillBoutiquePhoto11,
        "hillBoutiquePhoto12": hillBoutiquePhoto12,
        "hillBoutiquePhoto13": hillBoutiquePhoto13,
        "hillBoutiquePhoto14": hillBoutiquePhoto14,
        "hillBoutiquePhoto15": hillBoutiquePhoto15,
        "hillBoutiquePhoto16": hillBoutiquePhoto16,
        "hillBoutiquePhoto17": hillBoutiquePhoto17,
        "hillBoutiquePhoto18": hillBoutiquePhoto18,
        "hillBoutiquePhoto19": hillBoutiquePhoto19,

        "mevaVillalariPhoto30": mevaVillalariPhoto30,
        "mevaVillalariPhoto1": mevaVillalariPhoto1,
        "mevaVillalariPhoto2": mevaVillalariPhoto2,
        "mevaVillalariPhoto3": mevaVillalariPhoto3,
        "mevaVillalariPhoto4": mevaVillalariPhoto4,
        "mevaVillalariPhoto6": mevaVillalariPhoto6,
        "mevaVillalariPhoto7": mevaVillalariPhoto7,
        "mevaVillalariPhoto8": mevaVillalariPhoto8,
        "mevaVillalariPhoto9": mevaVillalariPhoto9,
        "mevaVillalariPhoto10": mevaVillalariPhoto10,
        "mevaVillalariPhoto11": mevaVillalariPhoto11,
        "mevaVillalariPhoto12": mevaVillalariPhoto12,
        "mevaVillalariPhoto13": mevaVillalariPhoto13,
        "mevaVillalariPhoto14": mevaVillalariPhoto14,
        "mevaVillalariPhoto15": mevaVillalariPhoto15,
        "mevaVillalariPhoto16": mevaVillalariPhoto16,
        "mevaVillalariPhoto17": mevaVillalariPhoto17,
        "mevaVillalariPhoto18": mevaVillalariPhoto18,
        "mevaVillalariPhoto19": mevaVillalariPhoto19,
        "mevaVillalariPhoto20": mevaVillalariPhoto20,
        "mevaVillalariPhoto21": mevaVillalariPhoto21,
        "mevaVillalariPhoto22": mevaVillalariPhoto22,
        "mevaVillalariPhoto24": mevaVillalariPhoto24,
        "mevaVillalariPhoto25": mevaVillalariPhoto25,
        "mevaVillalariPhoto26": mevaVillalariPhoto26,
        "mevaVillalariPhoto27": mevaVillalariPhoto27,
        "mevaVillalariPhoto28": mevaVillalariPhoto28,
        "mevaVillalariPhoto29": mevaVillalariPhoto29,

        "otokentOtoEkspertizPhoto18": otokentOtoEkspertizPhoto18,
        "otokentOtoEkspertizPhoto1": otokentOtoEkspertizPhoto1,
        "otokentOtoEkspertizPhoto2": otokentOtoEkspertizPhoto2,
        "otokentOtoEkspertizPhoto3": otokentOtoEkspertizPhoto3,
        "otokentOtoEkspertizPhoto4": otokentOtoEkspertizPhoto4,
        "otokentOtoEkspertizPhoto5": otokentOtoEkspertizPhoto5,
        "otokentOtoEkspertizPhoto6": otokentOtoEkspertizPhoto6,
        "otokentOtoEkspertizPhoto7": otokentOtoEkspertizPhoto7,
        "otokentOtoEkspertizPhoto8": otokentOtoEkspertizPhoto8,
        "otokentOtoEkspertizPhoto9": otokentOtoEkspertizPhoto9,
        "otokentOtoEkspertizPhoto10": otokentOtoEkspertizPhoto10,
        "otokentOtoEkspertizPhoto11": otokentOtoEkspertizPhoto11,
        "otokentOtoEkspertizPhoto12": otokentOtoEkspertizPhoto12,
        "otokentOtoEkspertizPhoto13": otokentOtoEkspertizPhoto13,
        "otokentOtoEkspertizPhoto14": otokentOtoEkspertizPhoto14,
        "otokentOtoEkspertizPhoto15": otokentOtoEkspertizPhoto15,
        "otokentOtoEkspertizPhoto16": otokentOtoEkspertizPhoto16,
        "otokentOtoEkspertizPhoto17": otokentOtoEkspertizPhoto17,

        "vanKahvaltiPhoto15": vanKahvaltiPhoto15,
        "vanKahvaltiPhoto1": vanKahvaltiPhoto1,
        "vanKahvaltiPhoto2": vanKahvaltiPhoto2,
        "vanKahvaltiPhoto3": vanKahvaltiPhoto3,
        "vanKahvaltiPhoto4": vanKahvaltiPhoto4,
        "vanKahvaltiPhoto5": vanKahvaltiPhoto5,
        "vanKahvaltiPhoto6": vanKahvaltiPhoto6,
        "vanKahvaltiPhoto7": vanKahvaltiPhoto7,
        "vanKahvaltiPhoto8": vanKahvaltiPhoto8,
        "vanKahvaltiPhoto9": vanKahvaltiPhoto9,
        "vanKahvaltiPhoto10": vanKahvaltiPhoto10,
        "vanKahvaltiPhoto11": vanKahvaltiPhoto11,
        "vanKahvaltiPhoto12": vanKahvaltiPhoto12,
        "vanKahvaltiPhoto13": vanKahvaltiPhoto13,
        "vanKahvaltiPhoto14": vanKahvaltiPhoto14,

        "coffeeKioskPhoto5": coffeeKioskPhoto5,
        "coffeeKioskPhoto1": coffeeKioskPhoto1,
        "coffeeKioskPhoto2": coffeeKioskPhoto2,
        "coffeeKioskPhoto3": coffeeKioskPhoto3,
        "coffeeKioskPhoto4": coffeeKioskPhoto4,

        "germanyHukukOfficePhoto6": germanyHukukOfficePhoto6,
        "germanyHukukOfficePhoto1": germanyHukukOfficePhoto1,
        "germanyHukukOfficePhoto2": germanyHukukOfficePhoto2,
        "germanyHukukOfficePhoto3": germanyHukukOfficePhoto3,
        "germanyHukukOfficePhoto4": germanyHukukOfficePhoto4,
        "germanyHukukOfficePhoto5": germanyHukukOfficePhoto5,

        "germanyLoftDairePhoto3": germanyLoftDairePhoto3,
        "germanyLoftDairePhoto1": germanyLoftDairePhoto1,
        "germanyLoftDairePhoto2": germanyLoftDairePhoto2,

        "manolyaMobilyaMağazaPhoto7": manolyaMobilyaMağazaPhoto7,
        "manolyaMobilyaMağazaPhoto1": manolyaMobilyaMağazaPhoto1,
        "manolyaMobilyaMağazaPhoto2": manolyaMobilyaMağazaPhoto2,
        "manolyaMobilyaMağazaPhoto3": manolyaMobilyaMağazaPhoto3,
        "manolyaMobilyaMağazaPhoto4": manolyaMobilyaMağazaPhoto4,
        "manolyaMobilyaMağazaPhoto5": manolyaMobilyaMağazaPhoto5,
        "manolyaMobilyaMağazaPhoto6": manolyaMobilyaMağazaPhoto6,

        "urlaMustakilKonutPhoto4": urlaMustakilKonutPhoto4,
        "urlaMustakilKonutPhoto1": urlaMustakilKonutPhoto1,
        "urlaMustakilKonutPhoto2": urlaMustakilKonutPhoto2,
        "urlaMustakilKonutPhoto3": urlaMustakilKonutPhoto3,

        "yasamkentVillaPhoto21": yasamkentVillaPhoto21,
        "yasamkentVillaPhoto1": yasamkentVillaPhoto1,
        "yasamkentVillaPhoto2": yasamkentVillaPhoto2,
        "yasamkentVillaPhoto3": yasamkentVillaPhoto3,
        "yasamkentVillaPhoto4": yasamkentVillaPhoto4,
        "yasamkentVillaPhoto5": yasamkentVillaPhoto5,
        "yasamkentVillaPhoto6": yasamkentVillaPhoto6,
        "yasamkentVillaPhoto7": yasamkentVillaPhoto7,
        "yasamkentVillaPhoto8": yasamkentVillaPhoto8,
        "yasamkentVillaPhoto9": yasamkentVillaPhoto9,
        "yasamkentVillaPhoto10": yasamkentVillaPhoto10,
        "yasamkentVillaPhoto11": yasamkentVillaPhoto11,
        "yasamkentVillaPhoto12": yasamkentVillaPhoto12,
        "yasamkentVillaPhoto13": yasamkentVillaPhoto13,
        "yasamkentVillaPhoto14": yasamkentVillaPhoto14,
        "yasamkentVillaPhoto15": yasamkentVillaPhoto15,
        "yasamkentVillaPhoto16": yasamkentVillaPhoto16,
        "yasamkentVillaPhoto17": yasamkentVillaPhoto17,
        "yasamkentVillaPhoto18": yasamkentVillaPhoto18,
        "yasamkentVillaPhoto19": yasamkentVillaPhoto19,
        "yasamkentVillaPhoto20": yasamkentVillaPhoto20,

        "vigInternationalPhoto4": vigInternationalPhoto4,
        "vigInternationalPhoto1": vigInternationalPhoto1,
        "vigInternationalPhoto2": vigInternationalPhoto2,
        "vigInternationalPhoto3": vigInternationalPhoto3,

        "piyapInsaatPhoto12": piyapInsaatPhoto12,
        "piyapInsaatPhoto1": piyapInsaatPhoto1,
        "piyapInsaatPhoto2": piyapInsaatPhoto2,
        "piyapInsaatPhoto3": piyapInsaatPhoto3,
        "piyapInsaatPhoto4": piyapInsaatPhoto4,
        "piyapInsaatPhoto5": piyapInsaatPhoto5,
        "piyapInsaatPhoto6": piyapInsaatPhoto6,
        "piyapInsaatPhoto7": piyapInsaatPhoto7,
        "piyapInsaatPhoto8": piyapInsaatPhoto8,
        "piyapInsaatPhoto9": piyapInsaatPhoto9,
        "piyapInsaatPhoto10": piyapInsaatPhoto10,
        "piyapInsaatPhoto11": piyapInsaatPhoto11,

        "pasifikEurasiaPhoto4": pasifikEurasiaPhoto4,
        "pasifikEurasiaPhoto1": pasifikEurasiaPhoto1,
        "pasifikEurasiaPhoto2": pasifikEurasiaPhoto2,
        "pasifikEurasiaPhoto3": pasifikEurasiaPhoto3,

        "normTechnologyPhoto3": normTechnologyPhoto3,
        "normTechnologyPhoto1": normTechnologyPhoto1,
        "normTechnologyPhoto2": normTechnologyPhoto2,

        "gazselElektromekanikPhoto18": gazselElektromekanikPhoto18,
        "gazselElektromekanikPhoto1": gazselElektromekanikPhoto1,
        "gazselElektromekanikPhoto2": gazselElektromekanikPhoto2,
        "gazselElektromekanikPhoto3": gazselElektromekanikPhoto3,
        "gazselElektromekanikPhoto4": gazselElektromekanikPhoto4,
        "gazselElektromekanikPhoto5": gazselElektromekanikPhoto5,
        "gazselElektromekanikPhoto6": gazselElektromekanikPhoto6,
        "gazselElektromekanikPhoto7": gazselElektromekanikPhoto7,
        "gazselElektromekanikPhoto8": gazselElektromekanikPhoto8,
        "gazselElektromekanikPhoto9": gazselElektromekanikPhoto9,
        "gazselElektromekanikPhoto10": gazselElektromekanikPhoto10,
        "gazselElektromekanikPhoto11": gazselElektromekanikPhoto11,
        "gazselElektromekanikPhoto12": gazselElektromekanikPhoto12,
        "gazselElektromekanikPhoto13": gazselElektromekanikPhoto13,
        "gazselElektromekanikPhoto14": gazselElektromekanikPhoto14,
        "gazselElektromekanikPhoto15": gazselElektromekanikPhoto15,
        "gazselElektromekanikPhoto16": gazselElektromekanikPhoto16,
        "gazselElektromekanikPhoto17": gazselElektromekanikPhoto17,

        "arkasHoldingPhoto5": arkasHoldingPhoto5,
        "arkasHoldingPhoto1": arkasHoldingPhoto1,
        "arkasHoldingPhoto2": arkasHoldingPhoto2,
        "arkasHoldingPhoto3": arkasHoldingPhoto3,
        "arkasHoldingPhoto4": arkasHoldingPhoto4,

        "incekVillaPhoto13": incekVillaPhoto13,
        "incekVillaPhoto1": incekVillaPhoto1,
        "incekVillaPhoto2": incekVillaPhoto2,
        "incekVillaPhoto3": incekVillaPhoto3,
        "incekVillaPhoto4": incekVillaPhoto4,
        "incekVillaPhoto5": incekVillaPhoto5,
        "incekVillaPhoto6": incekVillaPhoto6,
        "incekVillaPhoto7": incekVillaPhoto7,
        "incekVillaPhoto8": incekVillaPhoto8,
        "incekVillaPhoto9": incekVillaPhoto9,
        "incekVillaPhoto10": incekVillaPhoto10,
        "incekVillaPhoto11": incekVillaPhoto11,
        "incekVillaPhoto12": incekVillaPhoto12,

        "isvicreKisBahcesiPhoto7" : isvicreKisBahcesiPhoto7,
        "isvicreKisBahcesiPhoto1" : isvicreKisBahcesiPhoto1,
        "isvicreKisBahcesiPhoto2" : isvicreKisBahcesiPhoto2,
        "isvicreKisBahcesiPhoto3" : isvicreKisBahcesiPhoto3,
        "isvicreKisBahcesiPhoto4" : isvicreKisBahcesiPhoto4,
        "isvicreKisBahcesiPhoto5" : isvicreKisBahcesiPhoto5,
        "isvicreKisBahcesiPhoto6" : isvicreKisBahcesiPhoto6,
    };

    const importedTitleImages = {
        taraparkVillaTitlePhoto,
        aydinlarVillaTitlePhoto,
        bahcelievlerKonutTitlePhoto,
        dbnInsaatOfficeTitlePhoto,
        hillBoutiqueTitlePhoto,
        ilonaKonutlarıTitlePhoto,
        mevaVillalariTitlePhoto,
        otokentOtoEkspertizTitlePhoto,
        vanKahvaltiTitlePhoto,
        germanyHukukOfficeTitlePhoto,
        germanyLoftDaireTitlePhoto,
        coffeeKioskTitlePhoto,
        manolyaMobilyaMağazaTitlePhoto,
        urlaMustakilKonutTitlePhoto,
        yasamkentVillaTitlePhoto,
        vigInternationalTitlePhoto,
        piyapInsaatTitlePhoto,
        pasifikEurasiaTitlePhoto,
        normTechnologyTitlePhoto,
        gazselElektromekanikTitlePhoto,
        arkasHoldingTitlePhoto,
        incekVillaTitlePhoto,
        isvicreKisBahcesiTitlePhoto,
    }

    const { projectname } = useParams();
    const project = projectsData.find(project => project.name === projectname);
    const [images, setImages] = useState([]);

    useEffect(() => {
        if (project) {
            setImages(project.projectsImagesName.map(imageName => importedImages[imageName]));
        }
    }, [project]);

    const [index, setIndex] = useState(-1);

    return (


        <div style={{ minHeight: "100vh" }}>
            <HelmetProvider>
                <Helmet>
                    <title>{project.title} {t("projectDetailPageHelmetTitle")}</title>
                    <meta name="description" content={t("projectDetailPageHelmetTitle")}></meta>
                </Helmet>
            </HelmetProvider>


            <Row className='startPageImgs'>
                <div className="contactPageStartPage">
                    <img className="contactPageStartPageImg" src={importedTitleImages[project.titleImageName]} alt={`${project.title} Proje Kapak Fotoğraf`} />
                    <h1 className="contactPageStartPageTitle">
                        {t(project.name)}<br />

                        {project.city}/{project.town}


                    </h1>
                </div>
            </Row>



            <div className='projectDetailTextArea'>
                <Container>
                    <Row>
                        <Col>
                            <h1 className='headerFont' style={{ color: "white" }}>{t(project.name)}</h1>
                            <p className='textFont' style={{ color: "white" }}>{`${project.city}/${project.town}`}</p>
                            <p className='textFont' style={{ color: "white" }}>{t(`${project.name}Desc`)}</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div style={{ marginTop: "50px" }}>
                <h1>{t("projectDetailPageTitle")}</h1>
            </div>

            <div className="projectsPhotoDiv" style={{ marginTop: "50px", marginBottom: "50px" }}>

                {images.map((src, i) => (
                    <LazyLoadImage
                        key={i}
                        src={src}
                        alt={`${projectname} ${i + 1}`}
                        onClick={() => setIndex(i)}
                        effect="blur" // this is optional
                    />
                ))}
            </div>

            <Lightbox
                index={index}
                plugins={[Zoom, Fullscreen, Thumbnails]}
                slides={images.map(src => ({ src }))}
                open={index >= 0}
                close={() => setIndex(-1)}
            />

        </div>
    );
}
export default ProjectDetail;
