import { useTranslation } from 'react-i18next';
import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";


import logo from "../Assets/melinteriordesignlogo.png";

import { ImBlog } from "react-icons/im";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
} from "react-icons/ai";
import { TbWorld } from "react-icons/tb";
import { FiPhone } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";



function NavBar() {
  const { t, i18n } = useTranslation();
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  window.addEventListener("scroll", scrollHandler);

  return (

    <Navbar
      expanded={expand}
      fixed="top"
      expand="lg"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container className="navbarContainerDiv">

        <Navbar.Brand href="/" className="navbarLogoBrand d-flex">
          <img src={logo} className="navbarLogoImg logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse className="navbarTextCollapse" id="responsive-navbar-nav">
          <Nav defaultActiveKey="#home">
            <Nav.Item>
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> <span style={{ whiteSpace: 'nowrap' }}>{t("navbarHomepage")}</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <FiUsers style={{ marginBottom: "2px" }} />  <span style={{ whiteSpace: 'nowrap' }}>{t("navbarWeUs")}</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/project"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineFundProjectionScreen
                  style={{ marginBottom: "2px" }}
                />{" "}
                <span style={{ whiteSpace: 'nowrap' }}>{t("navbarProjects")}</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/contact"
                onClick={() => updateExpanded(false)}
              >
                <FiPhone style={{ marginBottom: "2px" }} /> <span style={{ whiteSpace: 'nowrap' }}>{t("navbarContact")}</span>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link
                as={Link}
                to="/defineyourstyle"
                onClick={() => updateExpanded(false)}
              >
                <ImBlog style={{ marginBottom: "2px" }} /> <span style={{ whiteSpace: 'nowrap' }}>{t("navbarStilDesign")}</span>
              </Nav.Link>
            </Nav.Item>

          </Nav>
        </Navbar.Collapse>

        <NavbarCollapse className="navbarLanguageCollapse ">

          <NavDropdown
          title={ <span style={{color:"white"}}>
          <TbWorld style={{color:"white",marginRight:"5px",paddingBottom:"2px"}} size={"24px"} color='white' />
          {i18n.language.toLocaleUpperCase()}</span>} id="basic-nav-dropdown">

            <NavDropdown.Item onClick={() => changeLanguage('tr')}>TR</NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('en')}>EN</NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('de')}>DE</NavDropdown.Item>
          </NavDropdown>

        </NavbarCollapse>

      </Container>
    </Navbar>
  );
}

export default NavBar;
